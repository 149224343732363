<template>
  <div id="addressadd">
    <van-form @submit="onSubmit">
      <van-field
        name="consignee"
        v-model="consignee"
        label="收 货 人"
        placeholder="请输入收货人姓名"
        :rules="[{ required: true,pattern: /^[\s\S]*.*[^\s][\s\S]*$/, message: '请填写用户名！' }]"
      />
      <van-field
        name="phone"
        v-model="phone"
        type="tel"
        label="联系电话"
        placeholder="请输入收货手机号"
        :rules="[
          {
            pattern: /^1[3|4|5|7|8][0-9]{9}$/,
            message: '请输入正确的手机号码！',
          },
        ]"
      />
      <van-field
        name="zipcode"
        v-model="zipcode"
        type="text"
        label="邮政编号"
        placeholder="请输入邮政编号"
        :rules="[
          {
            validator: asyncZipcode,
            message: '请输入正确的邮政编码！',
          },
        ]"
        
      />
      <van-field
        v-model="fieldValue"
        name="fieldValue"
        is-link
        readonly
        label="地区"
        placeholder="请选择所在地区"
        @click="show = true"
        :rules="[
          {
            validator: asyncArea,
            message: '请选择所在地区！',
          },
        ]"
      />
      <van-popup v-model="show" round position="bottom">
        <van-cascader
          v-model="cascaderValue"
          name="cascaderValue"
          title="请选择所在地区"
          :options="options"
          @close="show = false"
          @change="onChange"
          @finish="onFinish"
        />
      </van-popup>
      <van-field
        name="detail"
        rows="2"
        autosize
        label="收货地址"
        v-model="detail"
        type="textarea"
        placeholder="请填写详细地址"
        :rules="[{ validator: asyncValidator, message: '文本长度在8-128个字符' }]"
      />
      <van-field name="enable" label="默认地址">
        <template #input>
          <van-switch v-model="enable" size="20" />
        </template>
      </van-field>
      <div class="quit">
        <van-button round block type="info" native-type="submit"
          >保存</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import { Notify,Toast } from "vant";
import {
  addAddress,
  getAreaInformation,
  getAddressByAddressId,
  updateaddress,
} from "./service";

let cascaderArrayValue = [];

export default {
  data() {
    return {
      detail: "", //详细收获地址
      consignee: "", //收货人
      zipcode: "",
      phone: "", //收获人电话
      show: false,
      fieldValue: "",
      cascaderValue: "",
      enable: false,
      // 选项列表，children 代表子选项，支持多级嵌套
      options: [
        {
          text: "浙江省",
          value: "330000",
          children: [{ text: "杭州市", value: "330100" }],
        },
        {
          text: "江苏省",
          value: "320000",
          children: [{ text: "南京市", value: "320100" }],
        },
      ],
    };
  },
  mounted() {
    if (this.$route.path === "/addressEdit") {
      getAddressByAddressId(this.$route.query.addressId).then((res) => {
        if (res.status === "0") {
          this.detail = res.data.detail;
          this.consignee = res.data.consignee;
          this.zipcode = res.data.zipcode;
          this.phone = res.data.phone;
          this.enable = res.data.type === 2 ? true : false;
          this.fieldValue = `${res.data.privinceName}/${res.data.cityName}/${res.data.districtName}`;
          cascaderArrayValue = [
            {
              privinceName: res.data.privinceName,
              privinceCode: res.data.privinceCode,
            },
            {
              cityName: res.data.cityName,
              cityCode: res.data.cityCode,
            },
            {
              districtName: res.data.districtName,
              districtCode: res.data.districtCode,
            },
          ];
        }
      });
    }

    getAreaInformation({ "qp-pid-eq": 100000 }).then((res) => {
      this.options = res.data.map((item) => ({
        value: item.id,
        text: item.name,
        level: item.level,
      }));
    });
  },
  methods: {
    async onSubmitData(values) {
      let res = null;
      let tipMessage = "";

      if (this.$route.path === "/addressEdit") {
        res = await updateaddress(values, this.$route.query.addressId);
        if (res.status == 0) {
          tipMessage = "编辑成功";
        } else {
          Notify({ type: "danger", message: res.msg });
        }
      } else {
        res = await addAddress(values);
        if (res.status == 0) {
          tipMessage = "新增成功";
        } else {
          tipMessage = "新增失败";
          Notify({ type: "danger", message: res.msg });
        }
        
        Toast.success(tipMessage);
      }

      if (res.status === "0") {
        Notify({ type: "success", message: tipMessage });
        if (this.$route?.query?.page === "placeorder") {
          this.$router.push("/address?page=placeorder");
        } else {
          this.$router.push("/address");
        }
      }
    },
    async onChange({ value, selectedOptions }) {
      const { options } = this;
      if (selectedOptions.length === 3) {
        this.show = false;

        cascaderArrayValue = [
          {
            privinceName: selectedOptions[0].text,
            privinceCode: selectedOptions[0].value,
          },
          {
            cityName: selectedOptions[1].text,
            cityCode: selectedOptions[1].value,
          },
          {
            districtName: selectedOptions[2].text,
            districtCode: selectedOptions[2].value,
          },
        ];
      }
      //selectedOptions.length 为1时，直接找到这个有这个value的对象，然后增加其children，为2时，找到options有children的，并在其的有value的对象中增加children
      if (selectedOptions.length < 3) {
        getAreaInformation({ "qp-pid-eq": value }).then((res) => {
          if (selectedOptions.length === 1) {
            this.options= options.map((e) => {
              if (value == e.value) {
                e.children = res.data.map((item) => {
                  return {
                    value: item.id,
                    text: item.name,
                    level: item.level,
                  };
                });
              }
              return e;
            });
          } else {
            this.options = options.map((e) => {
              if (e.children) {
                e.children.map((event) => {
                  if (value == event.value) {
                    event.children = res.data.map((item) => {
                      return {
                        value: item.id,
                        text: item.name,
                        level: item.level,
                      };
                    });
                  }
                  return event
                });
              }
              return e
            });
          }
        });
      }
    },
    onSubmit(values) {
      this.onSubmitData({
        consignee: values.consignee,
        phone: values.phone,
        zipcode: values.zipcode,
        detail: values.detail,
        privinceName: cascaderArrayValue[0].privinceName,
        privinceCode: cascaderArrayValue[0].privinceCode,
        cityName: cascaderArrayValue[1].cityName,
        cityCode: cascaderArrayValue[1].cityCode,
        districtName: cascaderArrayValue[2].districtName,
        districtCode: cascaderArrayValue[2].districtCode,
        objId: "1385867852069736451",
        type: values.enable ? 2 : 1,
      });
    },
    onFinish({ selectedOptions }) {
      this.fieldValue = selectedOptions.map((option) => option.text).join("/");
    },
    //验证详细地址字符长度
    asyncValidator(val) {
      //这里是将双字节符转为两个单字节符，计算长度，转换成什么样的单字节符无所谓
      let len = val.replace(/[^\x00-\xff]/g,"01").length;
      if (len <= 128 && len>=8) {
        return true;
      }

      return false;
    },
    //验证地区选择是否选择完整
    asyncArea(val){
      if(val.split('/').length===3){
        return true
      }
      return false
    },
    //如果填写了邮政编码就验证邮政编码的正确性
    asyncZipcode(val){
      const reg = /^[0-9]{6}$/
      if(val){
        return reg.test(val)
      }
    }
  },
};
</script>

<style lang="less" scoped>
#addressadd {
  .quit {
    display: flex;
    align-items: center;
    margin-top: 50px;
    .btn {
      width: 96%;
      border: 0;
      margin: 0 auto;
      font-size: 16px;
      height: 45px;
      line-height: 20px;
      border-radius: 7px;
      color: #fff;
      background-color: rgba(2, 167, 240, 1);
    }
  }
}
</style>
