/*
 * @Autor: syq
 * @Date: 2021-07-12 16:37:32
 */

import request from "../../request/request";
import { stringify } from "qs";


//地址列表查询
const addressList = async (params) =>
  request({
    url: `/address?${stringify(params)}`,
    method: "get",
  });

//地址列表查询
const getAddressByAddressId = async (addressId) =>
  request({
    url: `/address/${addressId}`,
    method: "get",
  });

//新增地址
const addAddress = async (values) =>
  request({
    url: `/address`,
    method: "post",
    data: values,
  });

//删除地址
const deleteAddress = async (ids) =>
  request({
    url: `/address/${ids}`,
    method: "delete",
  });

const updateaddress = async (values, id) =>
  request({
    url: `/address/${id}`,
    method: "patch",
    data: values,
  });

// 获取省市区信息
const getAreaInformation = async (params) =>
  request({
    url: `/bscArea/getBscAreaList?${stringify(params)}`,
    method: "get",
  });

export {
  addressList,
  addAddress,
  updateaddress,
  getAreaInformation,
  getAddressByAddressId,
  deleteAddress,
};
