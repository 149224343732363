var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"addressadd"}},[_c('van-form',{on:{"submit":_vm.onSubmit}},[_c('van-field',{attrs:{"name":"consignee","label":"收 货 人","placeholder":"请输入收货人姓名","rules":[{ required: true,pattern: /^[\s\S]*.*[^\s][\s\S]*$/, message: '请填写用户名！' }]},model:{value:(_vm.consignee),callback:function ($$v) {_vm.consignee=$$v},expression:"consignee"}}),_c('van-field',{attrs:{"name":"phone","type":"tel","label":"联系电话","placeholder":"请输入收货手机号","rules":[
        {
          pattern: /^1[3|4|5|7|8][0-9]{9}$/,
          message: '请输入正确的手机号码！',
        } ]},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('van-field',{attrs:{"name":"zipcode","type":"text","label":"邮政编号","placeholder":"请输入邮政编号","rules":[
        {
          validator: _vm.asyncZipcode,
          message: '请输入正确的邮政编码！',
        } ]},model:{value:(_vm.zipcode),callback:function ($$v) {_vm.zipcode=$$v},expression:"zipcode"}}),_c('van-field',{attrs:{"name":"fieldValue","is-link":"","readonly":"","label":"地区","placeholder":"请选择所在地区","rules":[
        {
          validator: _vm.asyncArea,
          message: '请选择所在地区！',
        } ]},on:{"click":function($event){_vm.show = true}},model:{value:(_vm.fieldValue),callback:function ($$v) {_vm.fieldValue=$$v},expression:"fieldValue"}}),_c('van-popup',{attrs:{"round":"","position":"bottom"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('van-cascader',{attrs:{"name":"cascaderValue","title":"请选择所在地区","options":_vm.options},on:{"close":function($event){_vm.show = false},"change":_vm.onChange,"finish":_vm.onFinish},model:{value:(_vm.cascaderValue),callback:function ($$v) {_vm.cascaderValue=$$v},expression:"cascaderValue"}})],1),_c('van-field',{attrs:{"name":"detail","rows":"2","autosize":"","label":"收货地址","type":"textarea","placeholder":"请填写详细地址","rules":[{ validator: _vm.asyncValidator, message: '文本长度在8-128个字符' }]},model:{value:(_vm.detail),callback:function ($$v) {_vm.detail=$$v},expression:"detail"}}),_c('van-field',{attrs:{"name":"enable","label":"默认地址"},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('van-switch',{attrs:{"size":"20"},model:{value:(_vm.enable),callback:function ($$v) {_vm.enable=$$v},expression:"enable"}})]},proxy:true}])}),_c('div',{staticClass:"quit"},[_c('van-button',{attrs:{"round":"","block":"","type":"info","native-type":"submit"}},[_vm._v("保存")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }